'use client';
import Icon from '@elements/Icon';
import logo from '@elements/Logo';
import NextLink from '@elements/links/NextLink';
import { usePathname } from 'next/navigation';
import { MenuItems, settings } from './Navigation';
import { SideMenuNavigation } from './SideMenuNavigation';

const SideMenu = ({ setSidebarOpen }) => {
  const pathname = usePathname();

  const updatedMenuItems = MenuItems.map((item) => ({
    ...item,
    current: item.href === pathname,
  }));

  return (
    <div className="h-screen w-64 bg-primary-dark">
      <nav className="flex size-full flex-col justify-between px-3 py-6">
        <div className="flex items-start justify-between pb-16">
          <div className="my-auto">
            <NextLink href="/">
              <logo.bouwzo_light.svg className="h-8 pl-2" />
            </NextLink>
          </div>

          <div className="my-auto ml-3 flex h-7 items-center">
            <button
              type="button"
              className="rounded-md focus:outline-none focus:ring-0"
              onClick={() => setSidebarOpen(false)}
            >
              <span className="sr-only">Close panel</span>
              <Icon iconName="x" size="sm" color="text-white/90" />
            </button>
          </div>
        </div>
        <ul className="flex w-full flex-1 flex-col justify-between">
          <div className="flex flex-col gap-y-4">
            {updatedMenuItems.map((navigation, index) => (
              <SideMenuNavigation navigation={navigation} key={index} />
            ))}
          </div>
          <SideMenuNavigation navigation={settings} />
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
