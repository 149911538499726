export const MenuItems = [
  {
    href: '/admin',
    icon: 'home',
    label: 'Dashboard',
    current: true,
  },
  {
    href: '/admin/orders',
    icon: 'cart',
    label: 'Bestellingen',
    current: false,
    claims: ['sales_read', 'sales_create', 'sales_delete'],
  },
  {
    href: '/admin/products',
    icon: 'product',
    label: 'Producten',
    current: false,
    claims: ['products_read', 'products_create', 'products_delete'],
  },
  {
    href: '/admin/profiles',
    icon: 'user',
    label: 'Profielen',
    current: false,
    claims: ['profile_read', 'profile_create', 'profile_delete'],
  },
  {
    href: '/admin/subscriptions',
    icon: 'subs',
    label: 'Licenties',
    current: false,
    claims: ['subscription_read', 'subscription_create', 'subscription_delete'],
  },
  {
    href: '/admin/organization',
    icon: 'building',
    label: 'Organisatie',
    current: false,
    claims: ['organization_read', 'organization_create', 'organization_delete'],
  },
  {
    href: '/admin/payouts',
    icon: 'credit-card',
    label: 'Uitbetalingen',
    current: false,
    claims: ['payment_read'],
  },
  // REMOVED DUE TO INSTABILITY
  // {
  //   href: '/admin/bundles',
  //   icon: 'file-zipper',
  //   label: 'Bundles',
  //   current: false,
  //   claims: ['bundle_read', 'bundle_create'],
  // },
  {
    icon: 'plus',
    label: 'Aanmaken',
    current: false,
    claims: [
      'subscription_read',
      'subscription_create',
      'subscription_delete',
      'enterprise_subscription',
    ],
    children: [
      {
        href: '/admin/subscriptions/enterprise?state=create-enterprise',
        label: 'Enterprise',
        current: false,
        claims: ['enterprise_subscription', 'organization_create'],
      },
      {
        href: '/admin/products/create',
        label: 'Product',
        current: false,
        claims: ['products_create'],
      },
      {
        href: '/admin/item?state=create-item',
        label: 'Item',
        current: false,
        claims: ['bundle_read', 'item_create'],
      },
      {
        href: '/admin/claim?state=create-claim',
        label: 'Claim',
        current: false,
        claims: ['bundle_read', 'claim_create'],
      },
    ],
  },
  {
    icon: 'arrow-down',
    label: 'Downloaden',
    current: false,
    claims: ['download'],
    children: [
      {
        href: '/admin/newsletter',
        label: 'Nieuwsbrief',
        current: false,
        claims: ['newsletter_download'],
      },
    ],
  },
  {
    icon: 'cog',
    label: 'System',
    current: false,
    claims: [
      'applications_read',
      'applications_create',
      'applications_delete',
      'publisher_read',
      'publisher_create',
      'publisher_delete',
      'index_read',
      'index_create',
      'index_delete',
      'pdf_asset_read',
      'pdf_asset_create',
      'pdf_asset_delete',
      'licence_read',
      'licence_create',
      'licence_delete',
    ],
    children: [
      {
        href: '/admin/system/applications',
        label: 'Applications',
        current: false,
        claims: ['applications_read', 'applications_create', 'applications_delete'],
      },
      {
        href: '/admin/system/publisher',
        label: 'Publisher',
        current: false,
        claims: ['publisher_read', 'publisher_create', 'publisher_delete'],
      },
      {
        href: '/admin/system/index',
        label: 'Index',
        current: false,
        claims: ['index_read', 'index_create', 'index_delete'],
      },
      {
        href: '/admin/system/pdfasset',
        label: 'PDFAsset',
        current: false,
        claims: ['pdf_asset_read', 'pdf_asset_create', 'pdf_asset_delete'],
      },
      {
        href: '/admin/system/licenses',
        label: 'Licenses',
        current: false,
        claims: ['licence_read', 'licence_create', 'licence_delete'],
      },
    ],
  },
];

export const settings = {
  href: '/admin/settings',
  icon: 'cog',
  label: 'Instellingen',
  current: false,
  claims: [],
};
