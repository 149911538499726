import Icon from '@elements/Icon';
import NextLink from '@elements/links/NextLink';
import { Disclosure } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { getNewsletterSubscriptions } from '@providers/admin';
import WithClaim from 'components/auth/WithClaim';
import { AnimatePresence, motion } from 'framer-motion';
import * as XLSX from 'xlsx/xlsx.mjs';

async function exportData(setSidebarOpen) {
  const fileName = 'Nieuwsbrief';
  const response = await getNewsletterSubscriptions();
  if (response && response.status === 200) {
    const worksheet = XLSX.utils.json_to_sheet(response.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : 'data.xlsx');
    setSidebarOpen(false);
  }
}

const renderListItem = (item, current, setSidebarOpen) => (
  <WithClaim claim={item.claims} key={item.label}>
    <li
      className={classNames(
        current
          ? 'rounded-md bg-[#1853db] text-sm text-white'
          : 'rounded-md  text-sm text-gray-200 hover:bg-[#1853db] hover:text-white',
      )}
    >
      {item.label === 'Nieuwsbrief' ? (
        <button
          className="flex place-items-center gap-4 px-1 py-2"
          type="button"
          onClick={() => exportData(setSidebarOpen)}
        >
          <Icon iconName={item.icon ? item.icon : ''} overrideClass="w-6" />
          <div className="px-4">{item.label}</div>
        </button>
      ) : (
        <NextLink href={item.href}>
          <div className="flex place-items-center gap-4 px-1 py-2">
            <Icon iconName={item.icon ? item.icon : ''} overrideClass="w-6" />
            <div className="px-4">{item.label}</div>
          </div>
        </NextLink>
      )}
    </li>
  </WithClaim>
);

export const SideMenuNavigation = (props) => {
  const { href, icon, label, current, children, claims } = props.navigation;
  const { setSidebarOpen } = props;
  return (
    <WithClaim claim={claims}>
      <nav>
        {children && !href ? (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between gap-3 py-2 text-white">
                  <div className="flex place-items-center gap-4">
                    <Icon iconName={icon} overrideClass="w-6" />
                    <div className="px-5 text-sm">{label}</div>
                  </div>
                  <motion.div
                    initial={false}
                    animate={{ rotate: open ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Icon iconName="chevron-down" overrideClass="w-6" />
                  </motion.div>
                </Disclosure.Button>
                <AnimatePresence>
                  {open && (
                    <Disclosure.Panel
                      as={motion.div}
                      static
                      initial={{ maxHeight: 0, opacity: 0, overflow: 'hidden' }}
                      animate={{ maxHeight: '100vh', opacity: 1 }}
                      exit={{ maxHeight: 0, opacity: 0 }}
                      className="overflow-hidden"
                    >
                      {children.map((child) => renderListItem(child, current, setSidebarOpen))}
                    </Disclosure.Panel>
                  )}
                </AnimatePresence>
              </>
            )}
          </Disclosure>
        ) : (
          renderListItem(props.navigation, current, setSidebarOpen)
        )}
      </nav>
    </WithClaim>
  );
};
