'use client';

import { classNames } from '@helpers/classNames';
import WithClaim from 'components/auth/WithClaim';
import AdminNavbar from 'feature/admin/AdminNavBar';
import { MenuItems } from 'feature/admin/Menus/Navigation';
import SideMenu from 'feature/admin/Menus/SideMenu';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import '../styles/globals.css';

export default function RootLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [hideSidebar, setHideSidebar] = useState(false);

  function extractClaims(menuItems) {
    const allClaims = [];

    function extractClaimsRecursive(items) {
      items.forEach((item) => {
        if (item.claims) {
          allClaims.push(...item.claims);
        }

        if (item.children) {
          extractClaimsRecursive(item.children);
        }
      });
    }

    extractClaimsRecursive(menuItems);

    return Array.from(new Set(allClaims));
  }
  const allClaims = extractClaims(MenuItems);

  useEffect(() => {
    let timeoutId;

    if (!sidebarOpen) {
      timeoutId = setTimeout(() => {
        setHideSidebar(true);
      }, 300);
    } else {
      setHideSidebar(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sidebarOpen]);

  return (
    <html lang="en">
      <body className="flex h-screen">
        <WithClaim
          claim={allClaims}
          altComponent={
            <div className="mx-auto">
              <div className="flex h-[75vh] w-full items-center justify-center bg-white md:px-0">
                <div className="relative flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-primary px-4 py-8 shadow-2xl md:px-8 lg:px-24">
                  <p className="text-6xl font-bold tracking-wider text-white md:text-xl lg:text-2xl">
                    404
                  </p>
                  <p className="mt-4 text-2xl font-bold tracking-wider text-white md:text-3xl lg:text-5xl">
                    Pagina niet beschikbaar
                  </p>
                  <p className="my-4 border-b-2 pb-4 text-center text-white">
                    Oeps, de pagina die je zoekt kan niet gevonden worden.
                  </p>
                  <div className="flex items-center gap-4">
                    <a className="font-primary text-accent underline" href={'/'}>
                      Terug naar home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="flex w-full flex-col">
            {!sidebarOpen && (
              <AdminNavbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            )}
            <div className="flex">
              <motion.div
                className={classNames('z-50', hideSidebar && 'hidden')}
                initial={{ x: '-100%' }}
                animate={{ x: sidebarOpen ? '0%' : '-100%' }}
                transition={{ ease: 'easeInOut', duration: 0.3 }}
              >
                <SideMenu setSidebarOpen={setSidebarOpen} />
              </motion.div>

              <div className="grow overflow-x-hidden px-4 pt-4">{children}</div>
            </div>
          </div>
        </WithClaim>
      </body>
    </html>
  );
}
