'use client';

import Icon from '@elements/Icon';

export default function AdminNavbar({ sidebarOpen, setSidebarOpen }) {
  return (
    <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      {!sidebarOpen && (
        <button type="button" onClick={() => setSidebarOpen(true)}>
          <Icon iconName="bars" className="size-6" aria-hidden="true" />
        </button>
      )}
    </div>
  );
}
